import {ConfigurationProductInstructionsState} from "./productinstructions.model";
import {doesExist, hasValue} from "../../../../../../services/validation.service";

export const validateState = (state: ConfigurationProductInstructionsState) => {
    // validate input data
    return (doesExist(state.loadedData.configuration)
        && hasValue(state.actualData.productConfigurationId)
        && state.actualData.productConfigurationRunId != null);
};

export const mustCallApi = (state: ConfigurationProductInstructionsState): boolean => {
    return (state.loadedData.configuration?.productConfigurationId !== state.actualData.productConfigurationId
        ||
        state.loadedData.configuration?.productConfigurationRunId !== state.actualData.productConfigurationRunId);
};

export const canGotoNext = (state: ConfigurationProductInstructionsState): boolean => {
    return (state.command.evaluateProductInstructions.canExecute || state.command.setProductInstructions.canExecute);
};
