import "@medi-mtm/components";
import IConfiguration from "models/configurations/configuration";
import {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import {mTMAuthorisationService} from "services/authenticationmtm.service";
import {getConfigName} from "../../../../../../services/config.service";
import { ComponentDataEvent, FailedDataEvent, ProductionInstructionEvaluatedEvent, ProductionInstructionLoadedEvent } from "@medi-mtm/components";

export type ProductionInstructionsWrapperComponentProps = {
    mtmApiEndpointBase: string;
    orderConfiguration: IConfiguration;
    // eslint-disable-next-line
    productionInstructionCanEvaluate: (productionInstructions: any) => void;
    resetRestoreProductInstructions: (canExecute: boolean) => void;
    fileNotValid: (message: string) => void;
    updateProductConfiguration: (productConfigurationId: string, configurationRundId: number) => void;
    updateProductionInstructionsEvaluating: () => void;
    updateProductionInstructionsEvaluationFailed: (details: CustomEvent<FailedDataEvent>) => void;
    updateProductionInstructionsEvaluated: (details: CustomEvent<ProductionInstructionEvaluatedEvent>) => void;
    updateProductionInstructionsLoading: () => void;
    updateProductionInstructionsLoadingFailed: (details: CustomEvent<FailedDataEvent>) => void;
    updateProductionInstructionsLoaded: (details: CustomEvent<ProductionInstructionLoadedEvent>) => void;
    updateGetComponentState: (details: CustomEvent<ComponentDataEvent>) => void;
};

// eslint-disable-next-line
export const ProductionInstructionsWrapperComponent = forwardRef<any, ProductionInstructionsWrapperComponentProps>((props, ref) => {
    // eslint-disable-next-line
    const instructionRef = useRef(null) as any;

    useImperativeHandle(ref, () => ({
        evaluate() {
            if (instructionRef.current !== null) {
                // eslint-disable-next-line
                const component = (instructionRef.current as any);
                component.evaluate();
            }
        }
    }));

    useEffect(() => {
        // eslint-disable-next-line
        const component = (instructionRef.current as any);
        if (props.orderConfiguration.productConfigurationId && component) {
            const initComponent = async () => {
                const token = await mTMAuthorisationService.getAccessTokenMTMHeader();

                component.init({
                    AuthMode: "token",
                    Token: token,
                    endpoints: {
                        getProductionInstructions: `${props.mtmApiEndpointBase}/productConfiguration/$1/productioninstructions`,
                        evaluateProductionInstructions: `${props.mtmApiEndpointBase}/productconfiguration/productioninstructions`
                    },
                    Environment: getConfigName()
                });
                component.tokenRefreshOptions = { callback: () => { return mTMAuthorisationService.getAccessTokenMTMHeader(); } };
                component.setProductConfigurationId(props.orderConfiguration.productConfigurationId);
                component.setProductConfigurationRunId(props.orderConfiguration.productConfigurationRunId);

                component.load();
            };

            initComponent();
        }
    }, [props.orderConfiguration]);

    useEffect(() => {
        // eslint-disable-next-line
        const component = (instructionRef.current as any);

        const fileNotValidHandler = (event: { detail: string }) => {
            props.fileNotValid(event.detail);
        };

        const productionInstructionsEvaluatingChangedHandler = () => {
            props.updateProductionInstructionsEvaluating();
        };

        const productionInstructionsEvaluationFailedChangedHandler = (details) => {
            props.updateProductionInstructionsEvaluationFailed(details);
        };

        // eslint-disable-next-line
        const productionInstructionsEvaluatedChangedHandler = (details: CustomEvent<ProductionInstructionEvaluatedEvent>) => {
            if (details.detail.componentData.isValid) {
                props.updateProductConfiguration(details.detail.componentData.productConfigurationId, details.detail.componentData.productConfigurationRunId);
            }

            props.updateProductionInstructionsEvaluated(details);
        };

        const productionInstructionsLoadingChangedHandler = () => {
            props.updateProductionInstructionsLoading();
        };

        const productionInstructionsLoadingFailedChangedHandler = (details) => {
            props.updateProductionInstructionsLoadingFailed(details);
        };

        const productionInstructionsLoadedChangedHandler = (details) => {
            props.updateProductionInstructionsLoaded(details);
        };

        const getComponentStateChangedHandler = (details) => {
            props.updateGetComponentState(details);
        };

        if (component !== null) {
            component.addEventListener("fileNotValid", fileNotValidHandler);
            component.addEventListener("productionInstructionsEvaluating", productionInstructionsEvaluatingChangedHandler);
            component.addEventListener("productionInstructionsEvaluationFailed", productionInstructionsEvaluationFailedChangedHandler);
            component.addEventListener("productionInstructionsEvaluated", productionInstructionsEvaluatedChangedHandler);
            component.addEventListener("productionInstructionsLoading", productionInstructionsLoadingChangedHandler);
            component.addEventListener("productionInstructionsLoadingFailed", productionInstructionsLoadingFailedChangedHandler);
            component.addEventListener("productionInstructionsLoaded", productionInstructionsLoadedChangedHandler);
            component.addEventListener("getComponentState", getComponentStateChangedHandler);
        }
        return () => {
            if (component !== null) {
                component.removeEventListener("fileNotValid", fileNotValidHandler);
                component.removeEventListener("productionInstructionsEvaluating", productionInstructionsEvaluatingChangedHandler);
                component.removeEventListener("productionInstructionsEvaluationFailed", productionInstructionsEvaluationFailedChangedHandler);
                component.removeEventListener("productionInstructionsEvaluated", productionInstructionsEvaluatedChangedHandler);
                component.removeEventListener("productionInstructionsLoading", productionInstructionsLoadingChangedHandler);
                component.removeEventListener("productionInstructionsLoadingFailed", productionInstructionsLoadingFailedChangedHandler);
                component.removeEventListener("productionInstructionsLoaded", productionInstructionsLoadedChangedHandler);
                component.removeEventListener("getComponentState", getComponentStateChangedHandler);
            }
        };
    }, [props.productionInstructionCanEvaluate, props.fileNotValid, props.updateProductConfiguration]);

    return (
        <>
            {
                <x-productioninstruction
                    ref={instructionRef}
                ></x-productioninstruction>
            }
        </>
    );
});

ProductionInstructionsWrapperComponent.displayName = "ProductionInstructionsWrapper";
