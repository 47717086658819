import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "app/redux_store";
import React from "react";
import ConfigurationService from "services/configuration.service";
import {Massmask} from "@medi-mtm/components";
import {handleResponseErrors} from "shared/networking/error_handling";
import {mustCallApi} from "./measurements.reducer";
import {determineNextStep} from "configurations/shared/configuration_editor/redux/editor.reducer";
import {ConfigurationStepEnum} from "configurations/shared/configuration_editor/models/configuration_step.enum";
import {navigateTo} from "configurations/shared/configuration_editor/redux/editor.thunks";
import { gotoNextOnValid } from "./measurements.slice";
import IConfigurationMeasurementModel from "models/configurations/configuration_measurement";
import { doesExist } from "services/validation.service";

export const setMeasurements = createAsyncThunk(
    "configuration/measurements/set",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const configurationMode = (getState() as RootState).configuration_mode.mode;
        if (!mustCallApi(state.configuration_measurements, configurationMode)) {
            return {}; // skip api call
        }

        const configurationId: IConfigurationMeasurementModel = {
            hasMeasurement: state.configuration_measurements.actualData.hasMeasurement,
            productConfiguration: null
        };
        if (state.configuration_measurements.actualData.productConfigurationId 
            && state.configuration_measurements.actualData.hasMeasurement
        ) {
            configurationId.productConfiguration = { 
                id: state.configuration_measurements.actualData.productConfigurationId,
                runId: state.configuration_measurements.actualData.productConfigurationRunId ?? 1
            };
        } else if(doesExist(state.configuration_measurements.loadedData.configuration.productConfigurationId)
            && !state.configuration_measurements.actualData.hasMeasurement
        ){
            configurationId.productConfiguration = {
                id: state.configuration_measurements.loadedData.configuration.productConfigurationId,
                runId: state.configuration_measurements.loadedData.configuration.productConfigurationRunId ?? 1
            };
        }

        const response = await ConfigurationService.setMeasurements(
            state.configuration_measurements.loadedData.configuration.id, configurationId,
            configurationMode);

        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const getSetMeasurements = createAsyncThunk(
    "configuration/measurements/get",
    async (id: string, {getState, rejectWithValue}) => {
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.getSetMeasurements(id, mode);
        return handleResponseErrors(response, "Configuration", rejectWithValue);
    }
);

export const evaluateMeasurements = createAsyncThunk(
    "configuration/measurements/evaluate",
    async (massMask: React.MutableRefObject<Massmask>) => {
        massMask.current.evaluate();
    }
);

export const showEvaluationResultForProductLine = createAsyncThunk(
    "configuration/measurements/evaluate/show",
    async (massMask: React.MutableRefObject<Massmask>) => {
        massMask.current.showEvaluationResult();
    }
);

export const gotoNext = createAsyncThunk(
    "configuration/measurements/gotonext",
    async (massMask: React.MutableRefObject<Massmask>, {getState, dispatch}) => {
        const state = getState() as RootState;

        if(state.configuration_editor.loadedData.orderConfigurationState.readonly) {
            dispatch(navigateTo({
                targetStep: determineNextStep(ConfigurationStepEnum.Measurements, state.configuration_measurements.loadedData.configuration.productionType),
                configurationId: state.configuration_editor.loadedData.configurationId,
                productConfigurationId: state.configuration_editor.loadedData.productConfigurationId,
                productConfigurationRunId: state.configuration_editor.loadedData.productConfigurationRunId
            }));
            return;
        }

        if (state.configuration_measurements.command.evaluateMeasurements.canExecute) {            
            await dispatch(evaluateMeasurements(massMask));            
            dispatch(gotoNextOnValid());
            return;
        }
        await dispatch(setMeasurements());
    }
);
