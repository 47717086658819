import React, {useEffect, useRef, useState} from "react";
import {RouteComponentProps} from "@reach/router";
import {AppDispatch} from "app/redux_store";
import {useAppDispatch, useAppSelector} from "app/hook";
import {
    changeStep,
    goBack,
    resetGoBackToProductPreSelection,
    resetSetMeasurements,
    resetState,
    resetStepChange,
    setIsNavigate,
    setProductConfiguration,
    updateCanEvaluate,
    updateEvaluationResult,
    updateHasMeasurement,
} from "./redux/measurements.slice";
import {
    ConfigurationStepperComponent,
} from "configurations/shared/configuration_editor/components/configuration_stepper.component";
import {
    ConfigurationNavigationComponent,
} from "configurations/shared/configuration_editor/components/configuration_navigation.component";
import {
    getSetMeasurements,
    gotoNext,
    setMeasurements,
    showEvaluationResultForProductLine,
} from "./redux/measurements.thunks";
import {MassmaskWrapperComponent} from "./components/massmask_wrapper.component";
import {canGotoNext} from "./redux/measurements.reducer";
import Enumerable from "linq";
import {Alert, Switch} from "@mui/material";
import {ConfigurationStepEnum} from "configurations/shared/configuration_editor/models/configuration_step.enum";
import {StepDescriptor} from "configurations/shared/configuration_editor/models/step_descriptor.model";
import {INavigationParams} from "configurations/shared/configuration_editor/models/navigation_params.model";
import {IStepChange} from "configurations/shared/configuration_editor/models/stepChange.model";
import {ContentContainer} from "shared/components/container/ContentContainer";
import {
    ProductInformation,
} from "configurations/shared/configuration_editor/components/configuration_productinformation.component";
import IConfiguration from "../../../../../models/configurations/configuration";
import {CommentSection} from "../../../configuration_comments/components/comment_section.component";
import {CommentScope} from "../../../configuration_comments/comment_scope_enum";
import {ConfigurationMode} from "../../../configurationMode.model";
import {t} from "i18next";
import ErrorDialog from "../../../../../shared/components/error_dialog/error_dialog.component";
import {
    LoadingOverlayComponent,
    LoadingOverlayContainer
} from "../../../../../shared/components/loading_overlay/loading_overlay.component";
import {getConfig} from "../../../../../services/config.service";
import {updateAvailableSteps, updateStepperState} from "../../redux/stepper.slice";
import styled from "styled-components";
import {ErrorDialogButtons} from "shared/components/error_dialog/error_dialog.helper";
import {useApiError} from "shared/provider/api_error/api_error.provider";
import {CommonApiResultError} from "shared/networking/common_api_result";
import {usePendingChanges} from "shared/provider/orderconfiguration/orderconfiguration.provider";

const AlertWrapper = styled.div`
    margin-top: 40px;
`;

interface MeasurementsProps extends RouteComponentProps {
    configurationId: string;
    onNavigate: (
        next: StepDescriptor,
        params?: INavigationParams[]
    ) => void;
    getCustomerData: (configuration: IConfiguration) => void;
    configurationState: ConfigurationStepEnum;
}

export const MeasurementsComponent: React.FC<MeasurementsProps> = (props) => {
    const dispatch: AppDispatch = useAppDispatch();
    const massmaskRef = useRef(null);
    const state = useAppSelector((state) => state.configuration_measurements);
    const stepperState = useAppSelector((state) => state.configuration_stepper);

    const configurationMode = useAppSelector(
        (state) => state.configuration_mode
    ).mode;
    const [displayMassmask, setDisplayMassmask] = useState(
        configurationMode === ConfigurationMode.OrderConfiguration
        || (
            configurationMode === ConfigurationMode.QuotationConfiguration
            && state.actualData.hasMeasurement
        )
    );

    const [displayErrorDialog, setDisplayErrorDialog] = useState({
        isVisible: false,
        errorMessage: "",
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isMTMComponentLoading, setIsMTMComponentLoading] = useState(true);
    const {apiError, setApiError} = useApiError();
    const {hasPendingChanges, setPendingChanges} = usePendingChanges();

    const showError = (message: string) => {
        setDisplayErrorDialog({
            isVisible: true,
            errorMessage: message
        });
    };

    const hideError = () => {
        setDisplayErrorDialog({
            isVisible: false,
            errorMessage: "",
        });
    };

    const changeDisplayMassMask = (showMassMask: boolean) => {
        dispatch(updateHasMeasurement(!state.actualData.hasMeasurement));
        setDisplayMassmask(!showMassMask);
    };

    useEffect(() => {
        dispatch(updateStepperState(ConfigurationStepEnum.Measurements));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (!state.loadedData.isNavigating && props.configurationId) {
            dispatch(getSetMeasurements(props.configurationId));
        }
    }, [props.configurationId]);

    useEffect(() => {
        if (state.loadedData?.configuration) {
            setIsLoading(false);
        } else {
            setPendingChanges(false);
            setIsLoading(true);
        }
    }, [state.loadedData]);

    useEffect(() => {
        if (state.loadedData?.configuration !== null) {
            props.getCustomerData(state.loadedData.configuration);
            setDisplayMassmask(state.actualData.hasMeasurement);
        }
    }, [state.loadedData.configuration]);

    useEffect(() => {
        if (state.loadedData.configuration !== null) {
            if (state.loadedData.configuration.productionType !== null && state.loadedData.configuration.productionType !== undefined) {
                dispatch(updateAvailableSteps(state.loadedData.configuration.productionType));
            }
        }
    }, [state.loadedData.configuration]);

    useEffect(() => {
        if (state.query.get.status === "error") {
            showError(state.query.get.message);
        } else {
            hideError();
        }
    }, [state.query.get.status]);

    useEffect(() => {
        if (state.command.goBackToProductPreSelection.status === "success") {
            const target = stepperState.previousStep;
            dispatch(setIsNavigate());
            props.onNavigate(target);
            dispatch(resetGoBackToProductPreSelection());
        }
    }, [state.command.goBackToProductPreSelection.status]);

    useEffect(() => {
        if (state.command.setMeasurements.status === "success") {
            const target = stepperState.nextStep;
            setIsMTMComponentLoading(false);     
            props.onNavigate(target, [
                {
                    name: "productorderconfigurationid",
                    value: state.actualData.productConfigurationId,
                },
                {
                    name: "productorderconfigurationrunid",
                    value: state.actualData.productConfigurationRunId,
                },
            ]);
            dispatch(setIsNavigate());
            dispatch(resetSetMeasurements());       
        }
        if (state.command.setMeasurements.status === "error") {
            setIsMTMComponentLoading(false);
            dispatch(resetSetMeasurements());
            showError(state.command.setMeasurements.message);
        }
        if (state.command.setMeasurements.status === "pending") {
            setIsMTMComponentLoading(true);
        }
    }, [state.command.setMeasurements.status]);

    useEffect(() => {
        if (!displayMassmask) {
            setIsMTMComponentLoading(false);
        }
    }, [displayMassmask]);

    useEffect(() => {
        if (state.actualData.doSetMeasurements && state.actualData.isValid)
            dispatch(setMeasurements());
    }, [state.actualData.doSetMeasurements, state.actualData.isValid]);

    useEffect(() => {
        if (state.actualData.showEvaluationResultForQuality)
            dispatch(showEvaluationResultForProductLine(massmaskRef));
    }, [state.actualData.showEvaluationResultForQuality]);

    useEffect(() => {
        if (state.actualData.handleStepChange === true) {
            const target = state.loadedData.stepState.targetStep;
            dispatch(setIsNavigate());
            props.onNavigate(target);
            dispatch(updateStepperState(target.value));
            dispatch(resetStepChange());
        }
    }, [state.actualData.handleStepChange]);

    return (
        <LoadingOverlayContainer>
            {displayErrorDialog.isVisible ? (
                <ErrorDialog
                    message={displayErrorDialog.errorMessage}
                    apiError={apiError}
                    buttons={ErrorDialogButtons.getButtonsByError(apiError, () => {
                        dispatch(hideError);
                    })}
                />
            ) : (
                <>
                    {isLoading ? (
                        <LoadingOverlayComponent/>
                    ) : (
                        <>
                            {
                                (state.loadedData?.configuration.sku !== null) &&
                                <ProductInformation
                                    sku={state.loadedData.configuration.sku}
                                    skuLongIdentifier={state.loadedData.configuration.productName}/>
                            }

                            <ConfigurationStepperComponent
                                onStepChangeExecute={(step: IStepChange) => dispatch(changeStep(step))}
                                orderConfigurationStateStep={props.configurationState}/>

                            <LoadingOverlayContainer>
                                {isMTMComponentLoading &&
                                    <LoadingOverlayComponent></LoadingOverlayComponent>}

                                <ContentContainer>
                                    {configurationMode === ConfigurationMode.QuotationConfiguration && (
                                        <>
                                            {t("EnableMeasurementsComponent")}
                                            <Switch
                                                checked={displayMassmask}
                                                onChange={() => changeDisplayMassMask(displayMassmask)}
                                            />
                                        </>
                                    )}


                                    {displayMassmask &&
                                        <MassmaskWrapperComponent
                                            ref={massmaskRef}
                                            productConfigurationId={state.loadedData.configuration.productConfigurationId}
                                            productConfigurationRunId={state.loadedData.configuration.productConfigurationRunId}
                                            mainProductLineErpId={state.loadedData.configuration.mtmMainProductLine}
                                            bodyAreaErpId={state.loadedData.configuration.mtmBodyArea}
                                            articleTypeErpId={state.loadedData.configuration.productType}
                                            qualityErpId={state.loadedData.configuration.productLine}
                                            selectedSide={state.loadedData.configuration.mtmSide}
                                            processType={configurationMode}                                            
                                            updateShowEvaluationResultInMassmask={true}
                                            footOptionErpId={state.loadedData.configuration.mtmFoot}
                                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                                            updateMassMaskEvaluating={() => {
                                                setIsMTMComponentLoading(true);
                                            }}
                                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                                            updateMassMaskEvaluationFailed={(details) => {
                                                setApiError(details.detail.error as unknown as CommonApiResultError);
                                                showError("");
                                                setIsMTMComponentLoading(false);
                                            }}
                                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                                            updateMassMaskEvaluated={(details) => {
                                                setIsMTMComponentLoading(false);
                                                dispatch(updateEvaluationResult(details.detail.evaluationResult));
                                            }}
                                            updateMassMaskLoading={() => {
                                                setIsMTMComponentLoading(true);
                                            }}
                                            updateMassMaskLoadingFailed={(details) => {
                                                setApiError(details.detail.error as unknown as CommonApiResultError);
                                                showError("");
                                                setIsMTMComponentLoading(false);
                                            }}
                                            updateMassMaskLoaded={(details) => {
                                                if (details.detail.componentData.isModified)
                                                    setPendingChanges(true);
    
                                                if (details.detail.evaluationResult)
                                                    dispatch(updateEvaluationResult(details.detail.evaluationResult));
                                                
                                                setIsMTMComponentLoading(false);
                                            }}
                                            updateGetComponentState={(details) => {
                                                dispatch(setProductConfiguration({ id: details.detail.productConfigurationId, runId: details.detail.productConfigurationRunId}));
                                                if (details.detail.isModified)
                                                    setPendingChanges(true);
                                                dispatch(updateCanEvaluate(details.detail.isValid));
                                            }}
                                            mtmApiEndpointBase={getConfig().mtmService.baseUrl}
                                        />
                                    }

                                    <AlertWrapper>
                                        {Enumerable.from(state.actualData.errorMessages).select(
                                            (error, index) => (
                                                <Alert key={index}
                                                       severity={error.severity === 3 ? "error" : "warning"}>
                                                    {error.errorMessage}
                                                </Alert>
                                            )
                                        )}
                                    </AlertWrapper>


                                    <CommentSection
                                        configurationId={props.configurationId}
                                        commentScope={CommentScope.MeasurementComments}
                                        marginTop={50}
                                    />

                                    <ConfigurationNavigationComponent
                                        canBack={state.loadedData.configuration != null}
                                        back={() => dispatch(goBack(stepperState.previousStep))}
                                        canNext={canGotoNext(state) || !displayMassmask}
                                        next={() => {
                                            if (
                                                configurationMode === ConfigurationMode.QuotationConfiguration &&
                                                !displayMassmask
                                            ) {
                                                dispatch(setMeasurements());
                                            } else dispatch(gotoNext(massmaskRef));
                                        }}
                                        hasPendingChanges={hasPendingChanges}
                                    />

                                </ContentContainer>
                            </LoadingOverlayContainer>
                        </>
                    )}
                </>
            )}
        </LoadingOverlayContainer>
    );
};
