import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/hook";
import {useTranslation} from "react-i18next";
import {setConfiguration} from "../redux/actionsmenu.slice";
import {AppDispatch} from "../../../../app/redux_store";
import {getOrderConfigurationState, resetReadonly} from "../redux/actionsmenu.thunk";
import {ProductionType} from "models/production_type.enum";
import {ConfigurationType} from "models/configuration_type.enum";
import {IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styled from "styled-components";
import {ConfigurationState} from "models/configuration_state.enum";
import {Dialog, DialogContent, DialogActions, Button} from "@mui/material";
import MeasurementDataSheetDialogComponent from "../components/configuration_measurementdatasheetdialog.component";

const StyledMoreVertIcon = styled(MoreVertIcon)({
    marginBottom: "4px"
});

interface ActionsMenuProps {
    orderConfigurationId: string
}

enum ActionType {
    MeasurementDataSheet,
    ResetReadonly
}

const ActionsMenuComponent: React.FC<ActionsMenuProps> = (props) => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.configuration_actionsmenu);
    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isResetReadonlyDialogOpen, setIsResetReadonlyDialogOpen] = React.useState(false);
    const [isMeasurementDataSheetDialogOpen, setIsMeasurementDataSheetDialogOpen] = React.useState(false);

    useEffect(() => {
        dispatch(setConfiguration(props.orderConfigurationId));
    }, [props.orderConfigurationId]);

    useEffect(() => {
        if(state.query.getOrderConfigurationState.status === "success")
            setIsLoading(false);
    }, [state.query.getOrderConfigurationState.status]);

    useEffect(() => {
        if(state.command.resetReadonly.status === "success")
           window.location.reload();
    }, [state.command.resetReadonly.status]);

    const handleIconButtonClick = (event) => {
        setIsLoading(true);
        if(state.query.getOrderConfigurationState.canExecute)
            dispatch(getOrderConfigurationState());
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (type: ActionType) => {
        switch(type)
        {
            case ActionType.MeasurementDataSheet:
                setIsMeasurementDataSheetDialogOpen(true);
                break;
            case ActionType.ResetReadonly:
                setIsResetReadonlyDialogOpen(true);
                break;
            default:
                break;
        }
        setAnchorEl(null);
    };

    const handleResetReadonlyDialogButtonClick = () =>
    {
        setIsResetReadonlyDialogOpen(false);
        dispatch(resetReadonly());
    };

    return <>
        <IconButton
            aria-controls="actions-menu-header"
            aria-haspopup="true"
            onClick={(event) => handleIconButtonClick(event)}
            size="large">
            <StyledMoreVertIcon/>
        </IconButton>
        <Menu
            id="actions-menu-header"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {setAnchorEl(null);}}
        >
            <MenuItem
                onClick={() => handleMenuItemClick(ActionType.MeasurementDataSheet)}
                disabled={!(!isLoading &&
                    state.query.getMeasurementDataSheet.canExecute &&
                    state.command.sendMeasurementDataSheet.canExecute &&
                    state.loadedData.orderConfigurationState?.productionType == ProductionType.MadeToMeasure &&
                    state.loadedData.orderConfigurationState?.configurationType == ConfigurationType.Order &&
                    (state.loadedData.orderConfigurationState?.orderConfigurationState == ConfigurationState.InProgressProductionInstructionsSelected || state.loadedData.orderConfigurationState?.orderConfigurationState == ConfigurationState.Completed || state.loadedData.orderConfigurationState?.orderConfigurationState == ConfigurationState.Finalized)
                )}
            >
                <>{t("MeasurementDataSheetAction")}</>
            </MenuItem>
            <MenuItem
                onClick={() => handleMenuItemClick(ActionType.ResetReadonly)}
                disabled={!(!isLoading &&
                    state.command.resetReadonly.canExecute &&
                    state.loadedData.orderConfigurationState?.readonly
                )}
            >
                <>{t("ResetReadonlyAction")}</>
            </MenuItem>
        </Menu>

        <MeasurementDataSheetDialogComponent isOpen={isMeasurementDataSheetDialogOpen} onClose={() => { setIsMeasurementDataSheetDialogOpen(false); }} />

        <Dialog open={isResetReadonlyDialogOpen} disableEscapeKeyDown={true}>
          <DialogContent>
            {t("ResetReadonlyDialogQuestion")}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleResetReadonlyDialogButtonClick} color="primary" autoFocus>
                {t("ResetReadonlyDialogButton")}
            </Button>
            <Button onClick={() => setIsResetReadonlyDialogOpen(false)} color="secondary">
                {t("CancelButton")}
            </Button>
          </DialogActions>
        </Dialog>
    </>;
};

export default ActionsMenuComponent;
