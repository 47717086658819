import LockService from "services/lock.service";
import {CommonApiResultError} from "shared/networking/common_api_result";

export type DialogButtons = {
    primary:
        {
            labelKey: string,
            onClick?: () => void
        },
    secondary?:
        {
            labelKey: string,
            onClick?: () => void
        }
};

export class ErrorDialogButtons {
    public static getButtonsByError(apiError: CommonApiResultError, afterClose?: () => unknown): DialogButtons {
        if (apiError?.errorCode === 409 && apiError?.errorSubCode === "00000005") {
            // locked
            return {
                primary: {
                    labelKey: "ReloadButton",
                    onClick: () => {
                        window.location.reload();
                    }
                },
                secondary: {
                    labelKey: "UnlockButton",
                    onClick: async() => {
                        await LockService.release(apiError.messageParameter["id"], true);
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);                        
                    }
                }
            };
        } else if (apiError?.errorCode === 409 && apiError?.errorSubCode === "00000010") {
            // reload if validation fails
            return {
                primary: {
                    labelKey: "ReloadButton",
                    onClick: () => {
                        window.location.reload();
                    }
                }
            };
        } else if (apiError?.system === "MTM" && apiError?.errorCode === 404 && apiError?.errorSubCode === "00000010") {
            // reload if validation fails
            return {
                primary: {
                    labelKey: "ReloadButton",
                    onClick: () => {
                        window.location.reload();
                    }
                }
            };
        }

        return {
            primary:
                {
                    labelKey: "CloseButton",
                    onClick: afterClose
                }
        };
    }
}
