import IConfiguration from "models/configurations/configuration";
import IConfigurationId from "models/configurations/configurationid";
import {IConfigurationAdditionsSelectionModel} from "models/configurations/configuration_additionsselection";
import IConfigurationProductLineSelectionModel from "models/configurations/configuration_productlineselection";
import {
    IConfigurationProductPreSelectionConfiguration,
    IConfigurationProductPreSelectionModel,
    IConfigurationProductPreSelectionResult
} from "models/configurations/configuration_productpreselection";
import IProductConfigurationId from "models/configurations/productconfigurationid";
import IConfigurationCreateModel from "../models/configurations/configuration_create";
import {ApiResult} from "../shared/networking/api_result";
import HttpService from "../shared/networking/http_service";
import {getConfig} from "./config.service";
import IConfigurationCompletion from "../models/configurations/configuration_completion";
import {Quantity} from "models/quantity";
import {
    CommentDateModel,
    CommentFilterModel,
    CommentModel
} from "../configurations/shared/configuration_comments/redux/configurations_comments.model";
import {ConfigurationMode} from "configurations/shared/configurationMode.model";
import {getEndpoint} from "./endpointGetter";
import IOrderConfigurationState from "models/configurations/configurationstate";
import IConfigurationMeasurementModel from "models/configurations/configuration_measurement";

class ConfigurationServiceAPI extends HttpService {

    public async getConfiguration(id: string, mode: ConfigurationMode): Promise<ApiResult<IConfiguration>> {
        const endpoint = getEndpoint(mode);
        return this.get<IConfiguration>(`/api/${endpoint}/${id}`);
    }

    public async getOrCreate(data: IConfigurationCreateModel, mode: ConfigurationMode): Promise<ApiResult<IConfigurationId>> {
        const endpoint = getEndpoint(mode);
        return this.postDataForData<IConfigurationCreateModel, IConfigurationId>(`/api/${endpoint}/getorcreate`, data);
    }

    public async getCreate(id: string, mode: ConfigurationMode): Promise<ApiResult<IConfiguration>> {
        const endpoint = getEndpoint(mode);
        return this.get<IConfiguration>(`/api/${endpoint}/${id}/create`);
    }

    public async postSKU(id: string, data: IConfigurationProductPreSelectionModel, mode: ConfigurationMode): Promise<ApiResult<IConfigurationProductPreSelectionResult>> {
        const endpoint = getEndpoint(mode);
        return this.postDataForData<IConfigurationProductPreSelectionModel, IConfigurationProductPreSelectionResult>(`/api/${endpoint}/${id}/preselectproduct`, data);
    }

    public async preselectProduct(id: string, data: IConfigurationProductPreSelectionModel, mode: ConfigurationMode): Promise<ApiResult<IConfigurationProductPreSelectionResult>> {
        const endpoint = getEndpoint(mode);
        return this.postDataForData<IConfigurationProductPreSelectionModel, IConfigurationProductPreSelectionResult>(`/api/${endpoint}/${id}/preselectproduct`, data);
    }

    public async getPreselectProduct(id: string, mode: ConfigurationMode): Promise<ApiResult<IConfiguration>> {
        const endpoint = getEndpoint(mode);
        return this.get<IConfiguration>(`/api/${endpoint}/${id}/preselectproduct`);
    }

    public async getPreselectedProductConfigurationOptions(id: string, mode: ConfigurationMode): Promise<ApiResult<IConfigurationProductPreSelectionConfiguration>> {
        const endpoint = getEndpoint(mode);
        return this.get<IConfigurationProductPreSelectionConfiguration>(`/api/${endpoint}/${id}/preselectproduct`);
    }

    public async setMeasurements(id: string, data: IConfigurationMeasurementModel, mode: ConfigurationMode): Promise<ApiResult<void>> {
        const endpoint = getEndpoint(mode);
        return this.post<IConfigurationMeasurementModel>(`/api/${endpoint}/${id}/setmeasurements`, data);
    }

    public async getSetMeasurements(id: string, mode: ConfigurationMode): Promise<ApiResult<IConfiguration>> {
        const endpoint = getEndpoint(mode);
        return this.get<IConfiguration>(`/api/${endpoint}/${id}/setmeasurements`);
    }

    public async getComments(configurationId: string, data: CommentFilterModel, mode: ConfigurationMode): Promise<ApiResult<CommentDateModel[]>> {
        const query = new URLSearchParams();
        for (const [key, value] of Object.entries(data)) {
            if (value != null) {
                query.append(key, value.toString());
            }
        }
        const endpoint = getEndpoint(mode);
        return this.get(`/api/${endpoint}/${configurationId}/comments?` + query.toString());
    }

    public async postComment(configurationId: string, data: CommentModel, mode: ConfigurationMode): Promise<ApiResult<void>> {
        const endpoint = getEndpoint(mode);
        return this.post(`/api/${endpoint}/${configurationId}/comments`, data);
    }

    public async deleteComment(configurationId: string, data: object, mode: ConfigurationMode): Promise<ApiResult<void>> {
        const endpoint = getEndpoint(mode);
        return this.delete(`/api/${endpoint}/${configurationId}/comments`, data);
    }

    public async selectProductLine(id: string, data: IConfigurationProductLineSelectionModel, mode: ConfigurationMode): Promise<ApiResult<void>> {
        const endpoint = getEndpoint(mode);
        return this.post<IConfigurationProductLineSelectionModel>(`/api/${endpoint}/${id}/selectproductline`, data);
    }

    public async getSelectProductLine(id: string, mode: ConfigurationMode): Promise<ApiResult<IConfiguration>> {
        const endpoint = getEndpoint(mode);
        return this.get<IConfiguration>(`/api/${endpoint}/${id}/selectproductline`);
    }

    public async selectProduct(id: string, data: string, mode: ConfigurationMode): Promise<ApiResult<void>> {
        const endpoint = getEndpoint(mode);
        return this.post<string>(`/api/${endpoint}/${id}/selectproduct`, data);
    }

    public async getSelectProduct(id: string, mode: ConfigurationMode): Promise<ApiResult<IConfiguration>> {
        const endpoint = getEndpoint(mode);
        return this.get<IConfiguration>(`/api/${endpoint}/${id}/selectproduct`);
    }

    public async setAdditions(id: string, data: IProductConfigurationId, mode: ConfigurationMode): Promise<ApiResult<void>> {
        const endpoint = getEndpoint(mode);
        return this.post<IProductConfigurationId>(`/api/${endpoint}/${id}/selectadditions`, data);
    }

    public async getAdditionsSelection(id: string, mode: ConfigurationMode): Promise<ApiResult<IConfigurationAdditionsSelectionModel>> {
        const endpoint = getEndpoint(mode);
        return this.get<IConfigurationAdditionsSelectionModel>(`/api/${endpoint}/${id}/selectadditions`);
    }

    public async setProductInstructions(id: string, data: IProductConfigurationId, mode: ConfigurationMode): Promise<ApiResult<void>> {
        const endpoint = getEndpoint(mode);
        return this.post<IProductConfigurationId>(`/api/${endpoint}/${id}/selectproductioninstructions`, data);
    }

    public async getProductInstructions(id: string, mode: ConfigurationMode): Promise<ApiResult<IConfiguration>> {
        const endpoint = getEndpoint(mode);
        return this.get<IConfiguration>(`/api/${endpoint}/${id}/selectproductioninstructions`);
    }

    public async completion(id: string, quantity: Quantity, commission: string, shipmentDate: string, mode: ConfigurationMode, finalize: boolean): Promise<ApiResult<void>> {
        const endpoint = getEndpoint(mode);
        const query = `/api/${endpoint}/${id}/complete?finalize=${finalize}`;
        let data = {
            quantity: quantity,
            commission: commission,
            shipmentDate: undefined
        };

        if (mode === ConfigurationMode.OrderConfiguration) {
            data = {...data, shipmentDate: shipmentDate};
        }

        return this.post<IConfigurationCompletion>(query, data);
    }

    public async getCompletion(id: string, mode: ConfigurationMode): Promise<ApiResult<IConfiguration>> {
        const endpoint = getEndpoint(mode);
        return this.get<IConfiguration>(`/api/${endpoint}/${id}/complete`);
    }

    public async getProductionInstructionDocumentToken(id: string, documentId: number): Promise<ApiResult<IConfiguration>> {
        return this.get<IConfiguration>(`/api/orderconfiguration/${id}/documents/productioninstruction/${documentId}`);
    }

    public async getAdditionDocumentToken(id: string, documentId: number): Promise<ApiResult<IConfiguration>> {
        return this.get<IConfiguration>(`/api/orderconfiguration/${id}/documents/position/${documentId}`);
    }

    public async getDocument(documentToken: string): Promise<ApiResult<Blob>> {
        return await this.downloadFile(`/api/document/${documentToken}`);
    }

    public async getOrderConfigurationValidationState(id: string, mode: ConfigurationMode): Promise<ApiResult<IOrderConfigurationState>> {
        const endpoint = getEndpoint(mode);
        return this.get<IOrderConfigurationState>(`/api/${endpoint}/${id}/state`);
    }

    public async getMeasurementDataSheetToken(id: string): Promise<ApiResult<string>> {
        return this.get<string>(`/api/orderconfiguration/${id}/measurementdatasheet`);
    }

    public async sendMeasurementDataSheet(id: string, recipients: string[]): Promise<ApiResult<boolean>> {
        return this.post(`/api/orderconfiguration/${id}/sendmeasurementdatasheet`, recipients);
    }

    public async resetReadonly(id: string, mode: ConfigurationMode): Promise<ApiResult<boolean>> {
        const endpoint = getEndpoint(mode);
        return this.postForData<boolean>(`/api/${endpoint}/${id}/resetreadonly`);
    }

    public async cloneMtmNumber(mtmNumber: string, targetId: string, mode: ConfigurationMode) : Promise<ApiResult<IConfigurationId>> {
        const endpoint = getEndpoint(mode);
        return this.postDataForData(`/api/${endpoint}/${targetId}/updatebymtmnumber`, {erpTenant: "TST", erpGroupCompanyNumber: 101, mtmNumber: mtmNumber});
    }

    public async createByMtmNumber(data: IConfigurationCreateModel, mode: ConfigurationMode): Promise<ApiResult<IConfigurationId>> {
        const endpoint = getEndpoint(mode);
        return this.postDataForData<IConfigurationCreateModel, IConfigurationId>(`/api/${endpoint}/createbymtmnumber`, data);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const ConfigurationService = new ConfigurationServiceAPI(TimeoutMilliseconds);

export default ConfigurationService;
