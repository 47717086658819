import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import IProductConfigurationId from "models/configurations/productconfigurationid";
import {ConfigurationStepEnum,} from "configurations/shared/configuration_editor/models/configuration_step.enum";
import {IStepChange} from "configurations/shared/configuration_editor/models/stepChange.model";
import {ConfigurationProductInstructionsState} from "./productinstructions.model";
import {
    evaluateProductInstructions,
    getProductInstructions,
    gotoNext,
    setProductInstructions
} from "./productinstructions.thunks";
import {StepDescriptor} from "../../../models/step_descriptor.model";
import {setErrorInState} from "shared/networking/error_handling";
import { PassOrderConfigurationProvider } from "shared/provider/orderconfiguration/orderconfiguration.provider";

const initialState: ConfigurationProductInstructionsState = {
    loadedData: {
        stepState: {
            currentStep: ConfigurationStepEnum.ProductionInstructionsSelection,
            configurationId: null,
        },
        configuration: null,
        isNavigating: false,
    },
    actualData: {
        productConfigurationId: null,
        productConfigurationRunId: null,
        doSetProductionInstructions: false,
        handleStepChange: false,
        isRestoreCycle: false,
    },
    command: {
        setProductInstructions: {
            status: "idle",
            canExecute: false
        },
        evaluateProductInstructions: {
            status: "idle",
            canExecute: true
        },
        goBackToAdditions: {
            status: "idle",
            canExecute: false
        }
    },
    query: {
        get: {
            status: "idle",
            canExecute: false
        }
    }
};

export const configurationProductInstructionsSlice = createSlice({
    name: "configuration/productinstructions",
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            PassOrderConfigurationProvider.setPendingChanges(false);
        },
        resetSetProductInstructions: (state) => {
            state.command.setProductInstructions.status = "idle";
            state.loadedData.stepState.targetStep = null;
        },
        resetGotoAdditions: (state) => {
            state.command.goBackToAdditions.status = "idle";
            state.loadedData.stepState.targetStep = null;
        },
        resetStepChange: (state) => {
            state.actualData.handleStepChange = false;
            state.loadedData.stepState.targetStep = null;
        },
        setIsNavigate: (state) => {
            state.loadedData.isNavigating = true;
        },
        updateCanEvaluate: (state, action: PayloadAction<boolean>) => {
            state.command.evaluateProductInstructions.canExecute = action.payload;
        },
        setProductConfiguration: (state, action: PayloadAction<IProductConfigurationId>) => {
            state.actualData.productConfigurationId = action.payload.id;
            state.actualData.productConfigurationRunId = action.payload.runId;
            state.actualData.doSetProductionInstructions = true;
        },
        resetRestoreProductInstructions: (state) => {
            state.actualData.isRestoreCycle = false;
        },
        changeStep: (state, action: PayloadAction<IStepChange>) => {
            state.loadedData.stepState.targetStep = action.payload.targetStep;
            state.actualData.handleStepChange = true;
        },
        goBack: (state, action: PayloadAction<StepDescriptor>) => {
            state.loadedData.stepState.targetStep = action.payload;
            state.actualData.handleStepChange = true;
        },
    }, extraReducers: (builder) => {
        builder

            // evaluateProductInstructions
            .addCase(evaluateProductInstructions.pending, (state) => {
                state.command.evaluateProductInstructions.status = "pending";
            })

            // getProductInstructions
            .addCase(getProductInstructions.pending, (state) => {
                state.query.get.status = "pending";
                state.query.get.canExecute = false;
            })
            .addCase(getProductInstructions.rejected, (state, action) => {
                setErrorInState(state.query.get, action);
            })
            .addCase(getProductInstructions.fulfilled, (state, action) => {
                state.query.get.status = "success";
                const data = action.payload.getData();
                state.actualData.productConfigurationId = data.productConfigurationId;
                state.actualData.productConfigurationRunId = data.productConfigurationRunId;
                state.loadedData.configuration = data;
                state.actualData.isRestoreCycle = data.state >= 7;
            })

            // setProductInstructions
            .addCase(setProductInstructions.pending, (state) => {
                state.command.setProductInstructions.status = "pending";
                state.command.setProductInstructions.canExecute = false;
            })
            .addCase(setProductInstructions.rejected, (state, action) => {
                setErrorInState(state.command.setProductInstructions, action);
            })
            .addCase(setProductInstructions.fulfilled, (state) => {
                state.command.setProductInstructions.status = "success";
            })

            // gotoNext
            .addCase(gotoNext.pending, () => {
                //state.actualData.canGoNext = false;
            })
            .addCase(gotoNext.fulfilled, () => {
                //state.actualData.canGoNext = true;
            });
    }
});

export const {
    resetState,
    resetGotoAdditions,
    resetSetProductInstructions,
    resetRestoreProductInstructions,
    resetStepChange,
    setIsNavigate,
    updateCanEvaluate,
    setProductConfiguration,
    changeStep,
    goBack
} = configurationProductInstructionsSlice.actions;

export default configurationProductInstructionsSlice.reducer;
