import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import IProductConfigurationId from "models/configurations/productconfigurationid";
import {ConfigurationMeasurementsState} from "./measurements.model";
import {handleEvaluation, isRestoreCycle, validateState} from "./measurements.reducer";
import {evaluateMeasurements, getSetMeasurements, gotoNext, setMeasurements} from "./measurements.thunks";
import IConfiguration from "models/configurations/configuration";
import {ConfigurationStepEnum,} from "configurations/shared/configuration_editor/models/configuration_step.enum";
import {IStepChange} from "configurations/shared/configuration_editor/models/stepChange.model";
import {StepDescriptor} from "../../../models/step_descriptor.model";
import {setErrorInState} from "shared/networking/error_handling";
import { QualityResultResponse } from "@medi-mtm/components";
import { PassOrderConfigurationProvider } from "shared/provider/orderconfiguration/orderconfiguration.provider";

const initialState: ConfigurationMeasurementsState = {
    loadedData: {
        stepState: {
            currentStep: ConfigurationStepEnum.Measurements,
            configurationId: null,
        },
        configuration: null,
        isNavigating: false
    },
    actualData: {
        isValid: false,
        isOnlyWarning: false,
        isRestoreCycle: false,
        doSetMeasurements: false,
        hasMeasurement: true,
        showEvaluationResultForQuality: false,
        errorMessages: [],
        productConfigurationId: null,
        productConfigurationRunId: null,
        handleStepChange: false,
    },
    command: {
        goBackToProductPreSelection: {
            status: "idle",
            canExecute: false
        },
        evaluateMeasurements:
            {
                status: "idle",
                canExecute: false
            },
        setMeasurements: {
            status: "idle",
            canExecute: false
        }
    },
    query: {
        get: {
            status: "idle",
            canExecute: false
        }
    }
};

export const configurationMeasurementsSlice = createSlice({
    name: "configuration/measurements",
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.query = initialState.query;
            state.command = initialState.command;
            PassOrderConfigurationProvider.setPendingChanges(false);
        },
        resetSetMeasurements: (state) => {
            state.command.setMeasurements.status = "idle";
            state.loadedData.stepState.targetStep = null;
        },
        resetGoBackToProductPreSelection: (state) => {
            state.command.goBackToProductPreSelection.status = "idle";
            state.loadedData.stepState.targetStep = null;
        },
        resetStepChange: (state) => {
            state.actualData.handleStepChange = false;
            state.loadedData.stepState.targetStep = null;
        },
        setIsNavigate: (state) => {
            state.loadedData.isNavigating = true;
        },
        setConfiguration: (state, action: PayloadAction<IConfiguration>) => {
            state.loadedData.configuration = action.payload;
            state.loadedData.stepState.configurationId = action.payload.id;
            state.actualData.isRestoreCycle = isRestoreCycle(state);
        },
        setProductConfiguration: (state, action: PayloadAction<IProductConfigurationId>) => {
            state.actualData.productConfigurationId = action.payload.id;
            state.actualData.productConfigurationRunId = action.payload.runId;
            state.command.setMeasurements.canExecute = validateState(state);
        },
        updateCanEvaluate: (state, action: PayloadAction<boolean>) => {
            state.actualData.isValid = false;
            state.actualData.isOnlyWarning = false;
            state.command.evaluateMeasurements.canExecute = action.payload;
        },
        updateHasMeasurement: (state, action: PayloadAction<boolean>) => {
            state.actualData.hasMeasurement = action.payload;
            state.command.setMeasurements.canExecute = validateState(state);
        },
        updateEvaluationResult: (state, action: PayloadAction<QualityResultResponse>) => {
            handleEvaluation(state, action.payload);
            state.command.evaluateMeasurements.status = "success";
            state.command.evaluateMeasurements.canExecute = !state.actualData.isValid && !state.actualData.isOnlyWarning;
            state.actualData.showEvaluationResultForQuality = !state.actualData.isValid;
        },
        restoreMassMask: (state) => {
            state.actualData.isRestoreCycle = false;
        },
        changeStep: (state, action: PayloadAction<IStepChange>) => {
            state.loadedData.stepState.targetStep = action.payload.targetStep;
            state.actualData.handleStepChange = true;
        },
        goBack: (state, action: PayloadAction<StepDescriptor>) => {
            state.loadedData.stepState.targetStep = action.payload;
            state.actualData.handleStepChange = true;
        },
        gotoNextOnValid: (state) => {
            state.actualData.isValid = false;
            state.actualData.isOnlyWarning = false;
            state.actualData.doSetMeasurements = true;
        }
    }, extraReducers: (builder) => {
        builder
            // getSetMeasurements
            .addCase(getSetMeasurements.pending, (state) => {
                state.query.get.status = "pending";
                state.query.get.canExecute = false;
            })
            .addCase(getSetMeasurements.rejected, (state, action) => {
                setErrorInState(state.query.get, action);
            })
            .addCase(getSetMeasurements.fulfilled, (state, action) => {
                state.query.get.status = "success";
                const data = action.payload.getData();
                state.loadedData.configuration = data;
                state.actualData.productConfigurationId = data.productConfigurationId;
                state.actualData.productConfigurationRunId = data.productConfigurationRunId;
                state.actualData.isRestoreCycle = (isRestoreCycle(state));
                state.actualData.hasMeasurement = data.hasMeasurements;
                state.command.setMeasurements.canExecute = validateState(state);
            })

            // evaluateMeasurements
            .addCase(evaluateMeasurements.pending, (state) => {
                state.command.evaluateMeasurements.status = "pending";
            })
            .addCase(evaluateMeasurements.fulfilled, () => {
                // "success" state is set in "updateEvaluationResult"
            })

            // setMeasurements
            .addCase(setMeasurements.pending, (state) => {
                state.command.setMeasurements.status = "pending";
                state.command.setMeasurements.canExecute = false;
            })
            .addCase(setMeasurements.rejected, (state, action) => {
                setErrorInState(state.command.setMeasurements, action);
            })
            .addCase(setMeasurements.fulfilled, (state) => {
                state.command.setMeasurements.status = "success";
            })

            // gotoNext
            .addCase(gotoNext.pending, () => {
                //state.actualData.canGoNext = false;
            }).addCase(gotoNext.fulfilled, () => {
            //state.actualData.canGoNext = true;
        });
    }
});

export const {
    resetState,
    resetSetMeasurements,
    resetGoBackToProductPreSelection,
    resetStepChange,
    setIsNavigate,
    setConfiguration,
    setProductConfiguration,
    updateCanEvaluate,
    updateEvaluationResult,
    updateHasMeasurement,
    restoreMassMask,
    changeStep,
    goBack,
    gotoNextOnValid
} = configurationMeasurementsSlice.actions;

export default configurationMeasurementsSlice.reducer;
